$(".link_item").click(function() {
  $("#control").addClass("theme--dark");
});

$(".dark__theme").click(function() {
  $(this).toggleClass("active", "inactive");
  $("#control").toggleClass("theme--dark", "");
});

$(".font__theme").click(function() {
  $(this).toggleClass("active", "inactive");
  $("#control").toggleClass("fontx--x2", "");
});

// var clicker = document.getElementById('clickme');
// clicker.onclick = function () {
//   document.getElementById('drawer').className = 'mdl-layout__drawer'; // only keep mdl-layout__drawer, is-visible should be removed
// };

$(".navArticle").click(function() {
  $("#drawer").addClass("is-visible");
  $(".mdl-custom__obfus").addClass("is-visible");
});

$(".mdl-custom__obfus").click(function() {
  $("#drawer").removeClass("is-visible");
  $("#menu_fast").removeClass("is-visible");
  $(".mdl-custom__obfus").removeClass("is-visible");
  // $('.mdl-layout__obfuscator').addClass('is-visible');
});

// menu header -- principal
$(".mdl-navigation__link-drawer").click(function() {
  $("#menu__home").removeClass("is-visible");
  $(".mdl-layout__obfuscator").removeClass("is-visible");
});

// cerrar el ofucus del submenu
$(".mdl-navigation__sub").click(function() {
  $("#drawer").removeClass("is-visible");
  $(".mdl-custom__obfus").removeClass("is-visible");
  $(".mdl-layout__obfuscator").removeClass("is-visible");
});

$(".menufast").click(function() {
  $("#menu_fast").addClass("is-visible");
  $(".mdl-custom__obfus").addClass("is-visible");
});

$(".menuform").click(function() {
  $("#menu_form").addClass("is-visible");
  $(".mdl-custom__obfus").addClass("is-visible");
  $(".mdl-layout__obfuscator").removeClass("is-visible");
});

$(".menuform__close").click(function() {
  $("#menu_form").removeClass("is-visible");
  $(".mdl-custom__obfus").removeClass("is-visible");
  $(".mdl-layout__obfuscator").removeClass("is-visible");
});

$(".menufast__close").click(function() {
  $("#menu_fast").removeClass("is-visible");
  $(".mdl-custom__obfus").removeClass("is-visible");
  $(".mdl-layout__obfuscator").removeClass("is-visible");
});

$(".mdl-custom__obfus").click(function() {
  $("#menu__home").removeClass("is-visible");
  $("#drawer").removeClass("is-visible");
  $("#menu_fast").removeClass("is-visible");
  $("#menu_form").removeClass("is-visible");
});

// $('.user_select').modaal();

// acordeon material
$(function() {
  $(".mdl-accordion__content").each(function() {
    let content = $(this);
    content.css("margin-top", -content.height());
  });
  $(document.body).on("click", ".mdl-accordion__button", function() {
    $(this)
      .parent(".mdl-accordion")
      .toggleClass("mdl-accordion--opened");
  });
});

$(".mdl-navigation__link.other").click(function() {
  $(this)
    .next()
    .toggleClass("active", "inactive");
});

$(".mdl-navigation__link").click(function() {
  //$(this).next().toggleClass("active", "inactive")
  $(this)
    .$(".mdl-accordion__content-wrapper")
    .toggleClass("overdata__active", "overdata__inactive");
});

$(document).ready(function() {
  $('img.imgdata[src$=".svg"]').each(function() {
    var $img = jQuery(this);
    var imgURL = $img.attr("src");
    var attributes = $img.prop("attributes");

    $.get(
      imgURL,
      function(data) {
        // Get the SVG tag, ignore the rest
        var $svg = jQuery(data).find("svg");

        // Remove any invalid XML tags
        $svg = $svg.removeAttr("xmlns:a");

        // Loop through IMG attributes and apply on SVG
        $.each(attributes, function() {
          $svg.attr(this.name, this.value);
        });

        // Replace IMG with SVG
        $img.replaceWith($svg);
      },
      "xml"
    );
  });
});

// slider range input material
// var myRange = document.querySelector('#rangeValue');
// var myValue = document.querySelector('#valueSlider');
// var container = document.querySelector('.mdl-slider__container');
// //var myUnits = 'myUnits';
// var off = myRange.offsetWidth / (parseInt(myRange.max) - parseInt(myRange.min));
// var px = ((myRange.valueAsNumber - parseInt(myRange.min)) * off) - (myValue.offsetParent.offsetWidth / 2);
// console.log('offset', myValue.offsetParent)
// myValue.parentElement.style.left = px + 'px';
// myValue.parentElement.style.top = myRange.offsetHeight + 'px';
// myValue.innerHTML = myRange.value;

// myRange.oninput = function () {
//   let px = ((myRange.valueAsNumber - parseInt(myRange.min)) * off) - (myValue.offsetWidth / 2);
//   myValue.innerHTML = myRange.value;
//   container.style.left = px + 'px';
// };

// single handler slider with bottom tooltip open
var pmdVerSliderTooltipBottom = document.getElementById(
  "pmd-range-tooltip-bottom"
);
noUiSlider.create(pmdVerSliderTooltipBottom, {
  start: [30],
  connect: "lower",
  tooltips: [
    wNumb({
      decimals: 0
    })
  ],
  range: {
    min: [0],
    max: [100]
  }
});
