$('.cycle-slideshow').cycle({
  fx: 'fade',
  slides: '>li',
  next: '.prevControl',
  prev: '.nextControl',
});

$('.staff-slideshow').cycle({
  fx: 'fade',
  slides: '>li',
  next: '.prevControl',
  prev: '.nextControl',
});

$('.slide__events').cycle({
  fx: 'fade',
  slides: '>li',
  next: '.next',
  prev: '.prev',
});


$('.slide__shop').cycle({
  fx: 'fade',
  slides: '>li',
  next: '.next',
  prev: '.prev',
});


$('.slide__home').cycle({
  fx: 'fade',
  slides: '>li',
  next: '.next',
  prev: '.prev',
});
